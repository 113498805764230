<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.ban-announce.overview"></custom-page-title>

                <p>Ez az oldal pénzintézetek bankszámla változások cégbírósági
                    jelentésével kapcsolatos útmutatókat tartalmazza.</p>

                <p>A cégek pénzforgalmi számlára vonatkozó adatait a számlát
                    vezető pénzügyi intézmény elektronikus adattovábbítás útján köteles bejelenteni
                    a cégbíróságon a számla megnyitását/megszűnését követő nyolc napon belül.<br>
                    A bejelentés nem esik illeték, illetve költségtérítés fizetési kötelezettség
                    alá. A pénzügyi intézmény a pénzforgalmi számla bejelentésére vonatkozó
                    kötelezettségének azt követően köteles eleget tenni, hogy a számlatulajdonos a
                    cégnyilvántartásba már bejegyzett cég esetén a cég bejegyzését, a cég
                    bejegyzése előtt pedig a bejegyzési kérelmének a cégbírósághoz történő
                    benyújtását a pénzügyi intézménynél megfelelően igazolta.</p>

                <p>Az adat bejegyzése és közzététele automatikusan történik. Ha
                    a számlavezető pénzügyi intézmény e kötelezettségének nem, vagy határidőn túl
                    tesz eleget, a cégbíróság erről tájékoztatja a Magyar Nemzeti Bankot.</p>

                <p>
                    <router-link to="/help/ban-announce-apply-report">Számlaszám-jelentés elkészítésének útmutatója</router-link>
                </p>
                <p>
                    <router-link to="/help/ban-announce-comparison">Számlaszámok egyeztetése a cégnyilvántartással</router-link>
                </p>
                <p>
                    <router-link to="/help/ban-announce-new-branch">Új bankfiók felvétele</router-link>
                </p>
                <p>
                    <router-link to="/help/ban-announce-download">Letöltések</router-link>
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpBanAnnounceOverview extends Vue {
}
</script>

<style scoped>

</style>